
import Vue from "vue";
import "@/lib/extentions";

export default Vue.extend<{}, {}, Computed, Props>({
  computed: {
    style() {
      return {
        borderRadius: `${this.radius}px`,
        padding: `${this.paddingVertical}px ${this.paddingHorizontal}px`,
        fontSize: `${this.fontSize}px`,
      };
    },
    text() {
      return this.value.addEllipsis(this.maxLength);
    },
  },
  props: {
    value: {
      type: String,
      required: true,
      default: "",
    },
    radius: {
      type: Number,
      default: 25,
    },
    paddingHorizontal: {
      type: Number,
      default: 8,
    },
    paddingVertical: {
      type: Number,
      default: 4,
    },
    fontSize: {
      type: Number,
      default: 10,
    },
    maxLength: {
      type: Number,
      default: 4,
    },
  },
});

interface Computed {
  style: { [key: string]: string };
  text: string;
}

interface Props {
  value: string;
  radius: number;
  paddingHorizontal: number;
  paddingVertical: number;
  fontSize: number;
  maxLength: number;
}
